@use "spotlab" as *;

mat-tab-header.mat-tab-header {
  background-color: white;
  border: none;
}

mat-tab.mat-tab-label {
  // background-color: #fff;
}

mat-tab-group {
  .mat-tab-label {
    i {
      font-size: $icon-size;
    }
    border-left: 1px solid gray;
  }

  .mat-tab-label-active {
    color: map-get($colors, "primary");
  }

  &.auto-size {
    .mat-tab-label {
      height: unset;
      min-width: unset;
    }
  }
  &.no-inkbar {
    mat-ink-bar {
      display: none;
    }
    .mat-tab-label {
      &:not(:first-child) {
        border-left: 1px solid gray;
      }
    }
  }
}
