@use 'spotlab' as *;
@use 'mixins';
span {
  &.badge {
    &.badge-success {
      background-color: map-get($colors, 'green');
    }

    &.badge-outline-success {
      @include mixins.badge-outline(map-get($colors, 'green'));
    }

    &.badge-outline-gray {
      @include mixins.badge-outline(map-get($colors, 'gray'));
    }

    &.badge-gray {
      background-color: map-get($colors, 'gray');
    }

    &.badge-warning {
      color: white;
    }
  }
}
